import React from "react";
import styled from "styled-components";

const ButtonContainer = styled.a`
  box-sizing: border-box;
  outline: none;
  display: none;
  cursor: pointer;
  margin-right: 1em;
  @media only screen and (max-width: 600px) {
    display: inline-block;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
      display: inline-block;
    }
`;
const Bar = styled.div`
  border-radius: 2px;
  outline: none;
  width: 35px;
  height: 2px;
  background-color: #717171;
  margin: 6px 0;
  transition: 0.3s;
`;

const MenuIcon = ({ opened }) => {
  return (
    <ButtonContainer className={opened ? "change container" : "container"}>
      <Bar className="bar1" />
      <Bar className="bar2" />
      <Bar className="bar3" />
    </ButtonContainer>
  );
};

export default MenuIcon;
