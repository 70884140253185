import React from "react";
import BurgerMenu from "./BurgerMenu";
import Navbar from "./Navbar";
import logo from "../assets/images/Lipperhub-Logo.svg";
import styled from "styled-components";
const Header = () => {
  const HeaderWrapper = styled.div`
    width: 100%;
    height: 4em;
    position: fixed;
    background-color: rgba(255, 255, 255, 0.95);
    z-index: 1;
  `;
  const LogoLipper = styled.img`
    height: 100%;
    width: 100%;
    transform: scale(0.3);
    &:hover {
      cursor: pointer;
    }
  `;

  return (
    <HeaderWrapper>
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center"
        }}
      >
        <div>
          <a href="#home">
            <LogoLipper src={logo} />
          </a>
        </div>

        <div className="header-links" style={{ height: "100%", width: "20%" }}>
          <Navbar />
        </div>
        <div>
          <BurgerMenu />
        </div>
      </div>
    </HeaderWrapper>
  );
};

export default Header;
