import React, { useState } from "react";
import MenuIcon from "./MenuIcon";
import styled from "styled-components";

const MenuOptions = styled.div`
  box-sizing: border-box;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.97);
  height: ${({ opened }) => (opened ? "auto" : 0)};
  flex-direction: column;
  display: flex;
  right: 0;
  transition: 3s;
  top: 4em;
  position: absolute;
  box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
`;

const Option = styled.a`
  text-decoration: none;
  font-family: "Montserrat", serif;
  margin: 0.9rem;
  color: #616162;
  font-weight: 500;
`;

const BurgerMenu = () => {
  const [opened, setOpened] = useState(false);

  return (
    <div>
      <span onClick={() => setOpened(!opened)}>
        <MenuIcon opened={opened} style={{ marginRight: "1em" }} />
      </span>
      <MenuOptions opened={opened} id="burger-menu">
        <Option onClick={() => setOpened(!opened)} href="#about">
          Sobre nós
        </Option>
        <Option onClick={() => setOpened(!opened)} href="#solutions">
          Soluções
        </Option>
        <Option onClick={() => setOpened(!opened)} href="#team">
          Equipe
        </Option>
        <Option onClick={() => setOpened(!opened)} href="#contact">
          Contato
        </Option>
      </MenuOptions>
    </div>
  );
};

export default BurgerMenu;
