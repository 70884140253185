import React from "react";
import styled from "styled-components";
import LikeAPPro from "../assets/images/likeappro.png";
import Etki from "../assets/images/ETKI_LOGO.png";
import Robos from "../assets/images/robot.svg";
import { Carousel } from "react-responsive-carousel";

const Solutions = () => {
  const SolutionsWrapper = styled.div`
    padding-top: 4em;
    height: calc(100vh - 4em);
    width: 100%;
    box-sizing: content-box;
    @media only screen and (max-width: 600px) {
      padding: 0;
      height: auto;
    }
  `;
  const ConcreteSolutions = styled.div`
    background-color: #fafafa;
    height: 50vh;
    width: 100%;
    @media only screen and (max-width: 600px) {
      height: auto;
    }
  `;
  const SolutionTitle = styled.h3`
    font-family: "Montserrat", sans-serif;
    text-decoration: none;
    font-weight: 400;
    text-align: center;
    font-size: 2.3rem;
  `;

  const ImageSolution = styled.img`
    height: 3em;
  `;

  const CardSolution = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
    @media only screen and (max-width: 600px) {
      padding-top: 1em;
      width: 100%;
    }
  `;
  const CardWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media only screen and (max-width: 600px) {
      flex-direction: column;
      flex-wrap: wrap;
    }
  `;

  const SolutionName = styled.h3`
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 1.5em;
  `;

  const SolutionText = styled.p`
    text-align: center;
    font-weight: 300;
    font-family: "Montserrat", sans-serif;
    font-size: 1em;
    color: #898989;
    @media only screen and (max-width: 600px) {
      padding: 0 1em;
      
    }
  `;

  const FakeLink = styled.span`
    text-decoration: none;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    font-size: 0.8em;
    color: #70b5c5;
  `;

  const SolutionLink = styled.a`
    text-decoration: none;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    font-size: 0.8em;
    color: #70b5c5;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  `;

  const VideoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 60vh;
    width: 100%;
  `;

  return (
    <SolutionsWrapper>
      <ConcreteSolutions>
        <SolutionTitle style={{ paddingTop: "2.5em" }}>
          Soluções Contretizadas
        </SolutionTitle>

        <CardWrapper>
          <CardSolution>
            <ImageSolution src={LikeAPPro} />
            <SolutionName>LikeAPPro</SolutionName>
            <SolutionText>
              Nossa aplicação que conecta fotógrafos e influencers com o usuário
              em um marktplace de presets fotográficos
            </SolutionText>
            <SolutionLink href="https://likeappro.com/" target="_blank">
              Visite o site →
            </SolutionLink>
          </CardSolution>

          <CardSolution>
            <ImageSolution src={Etki} />
            <SolutionName>Etki</SolutionName>
            <SolutionText>
              Healthtech alicerçada em I.A., Machine Learning e utilização de
              Robôs para R.P.A.
            </SolutionText>
            <FakeLink>Em breve</FakeLink>
          </CardSolution>

          <CardSolution>
            <ImageSolution src={Robos} />
            <SolutionName>Mini Robôs</SolutionName>
            <SolutionText>
              Nossas API's disponibilizadas num ambiente que você pode testar e
              criar seus projetos
            </SolutionText>
            <SolutionLink href="https://minirobos.com.br/" target="_blank">
              Visite o site →
            </SolutionLink>
          </CardSolution>
        </CardWrapper>
      </ConcreteSolutions>

      <VideoWrapper className="video-wrapper">
        <SolutionTitle>Veja Mais</SolutionTitle>
        <Carousel
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          showArrows
          dynamicHeight
        >
          <div
            style={{
              position: "relative",
              paddingBottom: "56.25%",
              height: "0"
            }}
          >
            <iframe
              width="560"
              height="400"
              src="https://www.youtube.com/embed/bbiSsgDcEUA"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <div>
            <iframe
              width="560"
              height="400"
              src="https://www.youtube.com/embed/lZQjo8Qh_Ec"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </Carousel>
      </VideoWrapper>
    </SolutionsWrapper>
  );
};

export default Solutions;
