import React from "react";
import styled from "styled-components";
import EtkiLogo from "../assets/images/ETKI_LOGO.png";
const EtkiCard = () => {
  const EtkiCardWrapper = styled.div`
    position: absolute;
    top: 25%;
    left: 20%;
    border-radius: 3em;
    margin: 0 auto;
    z-index: 0;
    width: 60%;
    padding: 2em;
    background-color: rgba(250, 250, 250, 0.9);
    height: auto;
    flex-direction: column;
    align-content: center;

    @media only screen and (max-width: 600px) {
      display: flex;
      width: 100%;
      margin: 0;
      padding: 0;
      left: 0;
      border-radius: 0;
    }
  `;
  const EtkiParagraph = styled.p`
    font-family: "Droid Serif", serif;
    font-style: italic;
    font-size: 1.7em;
    color: #717171;
    @media only screen and (max-width: 600px) {
      font-size: 1em;
      padding: 0 1em;
    }
  `;

  const EtkiLogoImg = styled.img`
    transform: scale(0.75);
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    @media only screen and (max-width: 800px) {
      transform: scale(0.5);
    }
  `;
  const EtkiButton = styled.a`
    text-decoration: none;
    border: none;
    border-radius: 0.5em;
    padding: 1em 1.5em;
    color: #fafafa;
    background-color: #6c6fac;
    text-transform: uppercase;
    font-weight: bold;
    float: right;
    transition: 0.3s;
    &:hover {
      cursor: pointer;
      color: #6c6fac;
      background-color: #fafafa;
    }

    @media only screen and (max-width: 600px) {
      display: none;
    }
  `;

  return (
    <EtkiCardWrapper className="etki-card-wrapper">
      <EtkiLogoImg className="etki-logo" src={EtkiLogo} />
      <EtkiParagraph
        className="etik-parag"
        style={{ fontFamily: "Droid Serif, sans-serif " }}
      >
        O futuro da saúde nas mãos de quem está além do futuro.
      </EtkiParagraph>
      <EtkiParagraph className="etik-parag">
        Conheça a Etki, a segurança da inteligência digital, a assertividade de
        robôs e o cuidado de um ser humano
      </EtkiParagraph>
      <EtkiButton className="etik-btn" href="#solutions">
        Assista ao vídeo
      </EtkiButton>
    </EtkiCardWrapper>
  );
};

export default EtkiCard;
