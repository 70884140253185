import React, { useState } from "react";

import styled from "styled-components";

const Navbar = () => {
  const Links = styled.ul`
    height: 100%;
    display: flex;
    list-style-type: none;
    flex-direction: row;
    padding: 0;
    margin: 0;
    z-index: 1;
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
      display: none;
    }
  `;

  const Link = styled.a`
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 auto;
    color: #73787a;
    font-size: 0.85em;
    user-select: none;
    font-weight: bold;
    transition: 0.3s;
    &:hover {
      color: #000;
      cursor: pointer;
    }
  `;

  const [selectedItem, setSelectedItem] = useState("");

  return (
    <Links>
      <li style={{ margin: "0em 1em" }}>
        <Link
          className={selectedItem === "about" ? "selected-link" : ""}
          onClick={() => {
            setSelectedItem("about");
          }}
          href="#about"
        >
          Sobre nós
        </Link>
      </li>
      <li style={{ margin: "0em 1em" }}>
        <Link
          className={selectedItem === "solutions" ? "selected-link" : ""}
          onClick={() => {
            setSelectedItem("solutions");
          }}
          href="#solutions"
        >
          Soluções
        </Link>
      </li>
      <li style={{ margin: "0em 1em" }}>
        <Link
          className={selectedItem === "team" ? "selected-link" : ""}
          onClick={() => {
            setSelectedItem("team");
          }}
          href="#team"
        >
          Equipe
        </Link>
      </li>
      <li style={{ margin: "0em 1em" }}>
        <Link
          className={selectedItem === "contact" ? "selected-link" : ""}
          onClick={() => {
            setSelectedItem("contact");
          }}
          href="#contact"
        >
          Contato
        </Link>
      </li>
    </Links>
  );
};

export default Navbar;
