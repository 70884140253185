import React from "react";
import styled from "styled-components";
import Keyboard from "../assets/images/ContactImage.jpg";
const KnowMore = () => {
  const KMWrapper = styled.div`
    margin-top: 5em;
    background: #fff url(${Keyboard}) center center no-repeat;
    background-size: cover;
    width: 100%;
    height: 25em;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (max-width: 600px) {
      margin: 0;
      justify-content: space-evenly;
    }
  `;
  const KMTitle = styled.h1`
    padding-top: 2em;
    font-size: 2em;
    font-family: "Montserrat", serif;
    font-weight: 400;
    color: #fafafa;
    @media only screen and (max-width: 600px) {
      font-size: 1.5em;
    }
  `;
  const PhoneNumber = styled.a`
    text-decoration: none;
    color: #3d878e;

    &:active {
      text-decoration: underline;
    }
  `;
  const KMText = styled.h1`
    font-size: 1.3em;
    font-family: "Montserrat", serif;
    font-weight: 400;
    color: #fafafa;
    @media only screen and (max-width: 600px) {
      font-size: 1em;
    }
  `;
  return (
    <KMWrapper>
      <KMTitle>Quer saber mais?</KMTitle>
      <KMText>Praça General Sodré e Silva, 164 - Itaim Bibi,</KMText>
      <KMText>São Paulo - SP, 04571-040 </KMText>
      <KMText>ola@lipperhub.com </KMText>
      <KMText>
        Telefone:
        <PhoneNumber href="tel:+55 11 2818 3394"> +55 11 2818 3394</PhoneNumber>
      </KMText>
    </KMWrapper>
  );
};

export default KnowMore;
