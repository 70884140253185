import React from "react";
import Footer from "./components/Footer";
import Map from "./components/Map";
import KnowMore from "./components/KnowMore";
import Team from "./components/Team";
import Solutions from "./components/Solutions";
import EtkiContent from "./components/EtkiContent";
import Header from "./components/Header";
import UsContent from "./components/UsContent";
import "./main.css";



const App = () => {
  return (
    <div className="App">
      <Header />
      <a name="home" />
      <EtkiContent />
      <a name="about" />
      <UsContent />
      <a name="solutions" />
      <Solutions />
      <a name="team" />
      <Team />
      <a name="contact" />
      <KnowMore />
      <Map />
      <Footer />
    </div>
  );
};

export default App;
