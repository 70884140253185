import React from "react";

import styled from "styled-components";
import Computer from "../assets/images/LH_Ilustrações-01.svg";
import HandShake from "../assets/images/LH_Ilustrações-02.svg";
import Rating from "../assets/images/LH_Ilustrações-03.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const UsContent = () => {
  const RPAWrapper = styled.div`
    padding-top: 4em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    @media only screen and (max-width: 600px) {
      width: 100%;
      padding: 0;
      margin: 0;
    }
  `;
  const DoubleCard = styled.div`
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin: 2em 0;
    @media only screen and (max-width: 600px) {
      flex-direction: column;
      width: 80%;
      margin: 0;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
      flex-direction: column;
    }
  `;
  const CardImage = styled.div`
    height: auto;
    width: 80%;
    @media only screen and (max-width: 600px) {
      margin: 1em 0;
      width: 100%;
    }
  `;

  const CardText = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    font-family: "Montserrat", serif;
    justify-content: center;
    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  `;
  const TextTitle = styled.h3`
    font-weight: 400;
    font-size: 1.4em;
    @media only screen and (max-width: 600px) {
      font-size: 1em;
    }
  `;
  const TextCall = styled.h1`
    font-weight: 400;
    font-size: 2.5em;
    @media only screen and (max-width: 600px) {
      font-size: 1.5em;
    }
  `;
  const TextContent = styled.p`
    font-size: 1.3em;
    color: #73777c;
    @media only screen and (max-width: 600px) {
      font-size: 1em;
    }
  `;

  return (
    <RPAWrapper>
      <DoubleCard>
        <CardText>
          <TextTitle>Automação de processos</TextTitle>
          <TextCall>RPA – Robotic Process Automation</TextCall>
          <TextContent>
            Pesquisamos, planejamos e desenvolvemos soluções de automação de
            processos utilizando nossa tecnologia de extração de dados com OCR
            proprietário e Core de Machine Learning.
          </TextContent>
        </CardText>

        <CardImage>
          <img src={Computer} style={{ maxWidth: "100%", maxHeight: "100%" }} />
        </CardImage>
      </DoubleCard>

      <DoubleCard className="inverse-card">
        <CardImage>
          <img
            src={HandShake}
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </CardImage>

        <CardText>
          <TextTitle>Tecnologia em processos</TextTitle>
          <TextCall>Pensamos no negócio</TextCall>
          <TextContent>
            Conseguimos embarcar nos processos do nosso cliente toda a
            tecnologia necessária para melhorar a performance de nossos
            clientes. Propomos melhorias significativas através de extração de
            imagem e enriquecimento de dados.
          </TextContent>
        </CardText>
      </DoubleCard>

      <DoubleCard>
        <CardText>
          <TextTitle>Experiência bem desenhada</TextTitle>
          <TextCall>Produto como consequência</TextCall>
          <TextContent>
            Nosso desenvolvimento inicia no entendimento dos processos de nossos
            clientes alinhando com as melhores práticas de mercado e otimizando
            através de nossas tecnologias. Isso tudo combinado produz soluções
            que se tornam produtos com a mais alta tecnologia para toda a
            indústria.
          </TextContent>
        </CardText>

        <CardImage>
          <img src={Rating} style={{ maxWidth: "100%", maxHeight: "100%" }} />
        </CardImage>
      </DoubleCard>
    </RPAWrapper>
  );
};

export default UsContent;
