import React from "react";
import Navbar from "./Navbar";

import styled from "styled-components";
const Footer = () => {
  const FooterWrapper = styled.div`
    width: 100%;
    height: 4em;
    background-color: rgba(255, 255, 255, 0.95);
    z-index: 1;
  `;
  const Copyright = styled.p`
    font-size: 0.7em;
    font-family: "Montserrat", serif;
    font-weight: 400;
    color: #616162;
  `;

  const FooterLinks = styled.div`
    height: 100%;
    width: 20%;
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
      display: none;
    }
    @media only screen and (max-width: 600px) {
    display: none;
  }
  `;

  return (
    <FooterWrapper>
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center"
        }}
      >
        <div>
          <Copyright>© Copyright 2019 LipperHub</Copyright>
        </div>

        <FooterLinks>
          <Navbar />
        </FooterLinks>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
