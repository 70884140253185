import React from "react";

const Map = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#fafafa"
      }}
    >
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d3656.0842516791863!2d-46.69645088439883!3d-23.60131118466303!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e3!4m0!4m5!1s0x94ce5736b6ddd3ab%3A0xa8cf10b89c3c7ea2!2sPra%C3%A7a%20Gen.%20Sodr%C3%A9%20e%20Silva%2C%20164%20-%20Cidade%20Mon%C3%A7%C3%B5es%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2004571-040!3m2!1d-23.601311199999998!2d-46.6942622!5e0!3m2!1sen!2sbr!4v1568231140633!5m2!1sen!2sbr"
        width="100%"
        height="400"
        frameBorder="0"
        style={{ border: "0", padding: "em 0em" }}
        allowFullScreen=""
      />
    </div>
  );
};

export default Map;
