import React from "react";
import Background from "../assets/images/fundo.png"; // Tells Webpack this JS file uses this image
import EtkiCard from "./EtkiCard";
import styled from "styled-components";

const EtkiContent = () => {
  const EtkiWrapper = styled.div`
    padding-top: 4em;
    height: calc(100vh - 4em);
    width:100%;
    background: url(${Background}) no-repeat center;
    background-size: cover;
  `;

  return (
    <EtkiWrapper>
      <EtkiCard />
    </EtkiWrapper>
  );
};

export default EtkiContent;
