import React from "react";
import styled from "styled-components";
import Maurinho from "../assets/images/team/Maurinho.jpg";
import Vini from "../assets/images/team/Vinicius.jpg";
import Lucas from "../assets/images/team/Lucas.jpeg";
import Yan from "../assets/images/team/Yan.jpg";
import Pedro from "../assets/images/team/Pedro.jpeg";
const Team = () => {
  const TeamCardsWrapper = styled.div`
    padding-top: 10em;
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media only screen and (max-width: 600px) {
      display: flex;
      margin: 0;
      height: auto;
      padding-top: 1em;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
      padding-top: 1em;
    }
  `;
  const TeamCard = styled.div`
    width: 33.333%;
    height: 400px;
    padding: 0;
    margin: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;

    > span {
      opacity: 0;
    }

    &:hover {
      > span {
        opacity: 1;
      }
    }
    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  `;
  const TeamCardMask = styled.div`
    transition: 0.5s;
    position: absolute;
    background-color: rgba(61, 152, 153, 0.9);
    height: 100%;
    width: 100%;

    &:hover {
      background-color: rgba(61, 152, 153, 0.3);
    }
  `;

  const ImageTeam = styled.img`
    position: relative;
    height: 100%;
    width: 100%;
    object-fit: cover;
  `;

  const TeamTitle = styled.h3`
    color: #fafafa;
    padding-top: 3em;
    font-size: 3em;
    text-align: center;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    @media only screen and (max-width: 600px) {
      padding: 0 1em;
      font-size: 2em;
    }
  `;
  const TeamText = styled.p`
    font-family: "Montserrat", sans-serif;
    color: #ffffff;
    font-size: 1.2em;
    font-weight: 300;
    margin-bottom: 10em;
    text-align: center;
    @media only screen and (max-width: 600px) {
      padding: 0;
      font-size: 1em;
      text-align: center;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
     padding:0.2em;
     font-size:0.8em;
    }
  `;

  const TeamCardOverlayName = styled.span`
    font-family: "Droid Sans", sans-serif;
    font-weight: 600;
    font-size: 1.3em;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);

    color: #fafafa;
    position: absolute;
    transition: 0.5s;
    bottom: 50px;
    left: 16px;
  `;
  const TeamCardOverlayJob = styled.span`
    font-family: "Droid Sans", sans-serif;
    font-weight: 400;
    font-size: 1em;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
    color: #fafafa;
    position: absolute;
    transition: 0.5s;
    bottom: 30px;
    left: 16px;
  \`;
`;

  return (
    <TeamCardsWrapper>
      <TeamCard>
        <ImageTeam src={Maurinho} />
        <TeamCardMask />
        <TeamCardOverlayName>Maurinho Pimenta</TeamCardOverlayName>
        <TeamCardOverlayJob>Co-Founder</TeamCardOverlayJob>
      </TeamCard>

      <TeamCard style={{ backgroundColor: "#454656", flexDirection: "column" }}>
        <TeamTitle>EQUIPE</TeamTitle>
        <TeamText>
          Somos uma empresa jovem e amamos desafios. Pesquisar e desenvolver o
          que não existe, criar para nossos clientes soluções e serviços
          inovadores é o que nos motiva. Gostamos de trabalhar com o que existe
          de mais novo e estimulamos isso em nossos profissionais.
        </TeamText>
      </TeamCard>

      <TeamCard>
        <ImageTeam src={Vini} />
        <TeamCardMask />
        <TeamCardOverlayName>Vinicius Marinho</TeamCardOverlayName>
        <TeamCardOverlayJob>Full Stack Developer</TeamCardOverlayJob>
      </TeamCard>

      <TeamCard>
        <ImageTeam src={Yan} />
        <TeamCardMask />
        <TeamCardOverlayName>Yan Duarte</TeamCardOverlayName>
        <TeamCardOverlayJob>Development Manager</TeamCardOverlayJob>
      </TeamCard>

      <TeamCard>
        <ImageTeam src={Pedro} />
        <TeamCardMask />
        <TeamCardOverlayName>Pedro Sato</TeamCardOverlayName>
        <TeamCardOverlayJob>Full Stack Developer</TeamCardOverlayJob>
      </TeamCard>

      <TeamCard>
        <ImageTeam src={Lucas} />
        <TeamCardMask />
        <TeamCardOverlayName>Lucas Dall'Acqua</TeamCardOverlayName>
        <TeamCardOverlayJob>Full Stack Developer</TeamCardOverlayJob>
      </TeamCard>
    </TeamCardsWrapper>
  );
};

export default Team;
